import * as AncestryList from './characterNameService';
import * as BuildingList from './buildingNameService';
import * as SettlementList from './settlementNameService';
import * as LayoutState from './layoutService';

// The top-level state object
export interface ApplicationState {
    characterNames: AncestryList.CharacterNameState | undefined;
    buildingNames: BuildingList.BuildingNameState | undefined;
    settlements: SettlementList.SettlementState | undefined;
    layoutState: LayoutState.LayoutState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    characterNames: AncestryList.reducer,
    buildingNames: BuildingList.reducer,
    settlements: SettlementList.reducer,
    layoutState: LayoutState.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
